import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

function Page404() {


  const handleBackHome = () => {
    window.location.href = `/`;
  };
  return <div>
    <Result
      status="404"
      title=""
      subTitle="Xin lỗi, trang bạn tìm kiếm hiện tại đang bị lỗi!"
      extra={<Button type="primary" onClick={handleBackHome}>Trở lại trang chủ</Button>}
    />

  </div>;
}

export default Page404;
