/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, notification } from 'antd';
import UserPenIconComponent from '../../../../assets/icons/UserPenIcon';
import QuillEditorWrapper from '../../../../components/quill-editor/quill-editor.component';
import IntroductionService from '../../../../services/introduction';

const ManageIntroduction = () => {
    const [data, setData] = useState<Record<string, any>>({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    // const handleAdd = () => {
    //     setIsModalOpen(true);
    //     setEditingItem(null);
    //     form.resetFields();
    // };

    const handleUpdate = (record: any) => {
        setIsModalOpen(true);
        setEditingItem(record.id);

        form.setFieldsValue({ ...record });
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();

            if (editingItem) {
                // Updating existing news
                const obj = {
                    id: editingItem,
                    description: values.description,
                };
                const result = await IntroductionService.updateIntroductionCMS(obj);

                if (result.response?.data.message === "success") {
                    api.success({
                        message: 'Cập nhật thành công tin tức!',
                        showProgress: true,
                        pauseOnHover: true,
                    });
                } else {
                    api.error({
                        message: 'Có lỗi xảy ra khi cập nhật tin tức!',
                        description: result.response?.data.message || 'Unknown error',
                        showProgress: true,
                        pauseOnHover: true,
                    });
                }
            } else {
                // Creating new news
                const obj = {
                    description: values.description,
                };
                const result = await IntroductionService.createIntroductionCMS(obj);

                if (result.response?.data.message === "success") {
                    api.success({
                        message: 'Tạo thành công tin tức mới!',
                        showProgress: true,
                        pauseOnHover: true,
                    });
                } else {
                    api.error({
                        message: 'Vui lòng điền đầy đủ thông tin!',
                        description: result.response?.data.message || 'Unknown error',
                        showProgress: true,
                        pauseOnHover: true,
                    });
                }
            }

            fetchIntroduction();
            setIsModalOpen(false);
        } catch (error) {

            api.error({
                message: 'Vui lòng điền đầy đủ thông tin!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchIntroduction = async () => {
        try {
            const result = await IntroductionService.AdminGetAll();
            if (result.data.message === 'success') {
                const firstItem = result.data.data?.[0] || null;
                setData(firstItem);
            } else {
                notification.error({
                    message: 'Có lỗi xảy ra!',
                    description: result.data.message || 'Chi tiết lỗi không rõ.',
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            notification.error({
                message: 'Lỗi hệ thống máy chủ!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };

    useEffect(() => {
        fetchIntroduction();
    }, []);

    return (
        <div>
            {contextHolder}
            <Button type="default" style={{ marginBottom: 16 }}
                onClick={() => handleUpdate(data)}
            >
                Chỉnh sửa Giới thiệu
                <UserPenIconComponent style='w-5 h-5 fill-colorSecondary' />
            </Button>
            <div className='py-2.5 px-5 bg-white md:rounded-lg shadow-lg'>
                <div>
                    {data !== null && (
                        <div
                            dangerouslySetInnerHTML={{ __html: data.description }}
                        />
                    )}
                </div>
            </div>
            <Modal
                destroyOnClose={false}
                forceRender={true}
                title={editingItem ? 'Chỉnh sửa' : 'Thêm mới'}
                width={1000}
                open={isModalOpen}
                onOk={handleOk}
                okText={editingItem ? 'Lưu chỉnh sửa' : 'Thêm mới ngay'}
                cancelText="Hủy"
                onCancel={handleCancel}>
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="description"
                        label="Nội dung"
                        rules={[{ required: true, message: 'Vui lòng nhập nội dung!' }]}
                    >
                        <QuillEditorWrapper
                        />
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    );
};

export default ManageIntroduction;
