/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import {Collapse, Table, Button, Modal, Form, Input, Popconfirm, Select, Switch, Upload, InputNumber, Row, Col, Radio, Space, notification } from 'antd';
import UserPenIconComponent from '../../../../assets/icons/UserPenIcon';
import DeleteIconComponent from '../../../../assets/icons/DeleteIcon';
import ProductService from '../../../../services/product';
import ManufacturerService from '../../../../services/manufacturers';
import NewsService from '../../../../services/news';
import QuillEditorWrapper from '../../../../components/quill-editor/quill-editor.component';
import UploadIconComponent from '../../../../assets/icons/UploadIcon';
import FailPage from '../../../system/403'
import { parseDocument, DomUtils } from 'htmlparser2';
import Search, { SearchProps } from 'antd/es/input/Search';
import { width } from '@mui/system';
import { useNavigate } from 'react-router-dom';

interface ItemProduct {
    key: string;
    id: number;
    categoryName: string;
    categoryNameSlug: string;
    parentId: number;
    logo: string;
    status: string;
    subcategory: string;
    isShowHome: boolean;
    isShowMenu: boolean;
}



interface Attribute {
    attributeId: number;
    fixedPrice: number;
    discount: number;
    price: number;
    stockQuantity: number;
    isSelected: boolean;
}

interface AttributeGroup {
    attributeGroupName: string;
    attributes: Attribute[];
}

interface Manufacturer {
    key: number,
    id: number
    prdManufacturerName: string;
}

interface Product {
    key: number
    id: number;
    productCategoryId: number;
    prodName: string;
    logo: string;
    fixedPrice: number;
    price: number;
    description: string;
    promotion: string;
    quantity: number;
    specification: string;
    details: string;
    video: string;
    quarantee: number;
    status: string;
    categoryIds: number[];
    manufacturerIds: number[];
    attributeGroups: AttributeGroup[];
}

const { Panel } = Collapse;
const { Option } = Select;

const ManageItemsProduct
    : React.FC<{ title: string }> = ({ title }) => {
        // const [data, setData] = useState<Item[]>([]);

        const navigate = useNavigate(); 
        const [api, contextHolder] = notification.useNotification();

        const [isModalOpen, setIsModalOpen] = useState(false);
        const [editingItem, setEditingItem] = useState<any | null>(null);
        const [form] = Form.useForm();
        const [attributeGroupName, setAttributeGroupName] = useState('');
        // const [selectedAttributes, setSelectedAttributes] = useState([]);

        const [listCate, setListCate] = useState<ItemProduct[]>([]);

        const [categoryById, setCategoryById] = useState<ItemProduct>();

        const getAllCategories = async () => {
            try {
                const result = await ProductService.getAllCategory();
    
                if (result.data.message === 'success') {
                    const formattedData = await Promise.all(
                        result.data.data.map(async (category: any, index: number) => {
                            let subcategoryName: string = 'No Sub';
    
                            if (category.parentId) {
                                await getCategoryById(category.parentId);
                                subcategoryName = categoryById?.categoryName ? categoryById?.categoryName : "";
                            }
                            return {
                                key: category.id || index.toString(),
                                categoryName: category.categoryName,
                                categoryNameSlug: category.categoryNameSlug,
                                logo: category.logo,
                                status: category.status,
                                createdTime: category.createdTime,
                                subcategory: subcategoryName,
                                isShowHome: category.isShowHome,
                                isShowMenu: category.isShowMenu,
                                parentId: category.parentId,
                            };
                        })
                    );
                    setListCate(formattedData);
                }
                
            } catch (error) {
                console.log(error);
                navigate('./*')
            }
        };

        // const handleAttributeChange = (attributeId: number) => {
        //     setSelectedAttributes((prevSelected: any) =>
        //         prevSelected.includes(attributeId)
        //             ? prevSelected.filter((id: number) => id !== attributeId)
        //             : [...prevSelected, attributeId]
        //     );
        // };


        const [allProduct, setAllProduct] = useState<[]>([]);
        const [allProductAttribute, setAllProductAttribute] = useState<[]>([]);
        const [allManufacturer, setAllManufacturer] = useState<Manufacturer[]>([]);
        const getAllProduct = async () => {
            try {
                const result = await ProductService.getAllProduct();
                if (result.data.message === 'success') {
                    const formattedData: any = await Promise.all(
                        result.data.data.map(async (product: any, index: number) => {
                            return {
                                id: product.id,
                                prodName: product.prodName,
                                logo: product.logo,
                                fixedPrice: product.fixedPrice,
                                promotion: "string",
                                specification: product.specification,
                                details: product.details,
                                video: product.video,
                                quarantee: 0,
                                price: `₩${product.price.toLocaleString("ko-KR")}`,
                                description: product.description,
                                quantity: product.quantity,
                                status: product.status,
                                productCategoryId: product.productCategoryId,
                                manufacturerIds: product.manufacturerIds || [],
                                attributeGroups: product.attributeGroups || [],
                                categoryIds: product.categoryIds || [],
                            };
                        })
                    );
                    setAllProduct(formattedData)
                    setFilteredProducts(formattedData)
                }      
            } catch (error) {
                console.error('API call failed: ', error);
                navigate('./*');
            }
        }
        // console.log("Products", allProduct)


        const getAllProductAttribute = async () => {
            try {
                const result = await ProductService.getAllProductAttribute();
                if (result.data.message === 'success') {
                    setAllProductAttribute(result.data.data)
                }
            } catch (error) {
                console.error('API call failed: ', error);
                navigate('./*');
            }
        }

        const getAllManufacturer = async () => {
            try {
                const result = await ManufacturerService.getAllManufacturerCMS();
                if (result.data.message === 'success') {
                    const formattedData = await Promise.all(
                        result.data.data.map(async (item: any, index: number) => {
                            return {
                                key: item.id || index.toString(),
                                prdManufacturerName: item.prdManufacturerName,
                                status: item.status,
                                createdTime: item.createdTime,
                                updatedTime: item.updatedTime,
                            };
                        })
                    );
                    setAllManufacturer(formattedData)
                }          
            } catch (error) {
                navigate('./*');
            }
        }
        //   console.log("allProduct", allProduct)
        //   console.log("AllManufacturer", allManufacturer)
        // console.log("allProductAttribute", allProductAttribute)


        const getCategoryById = async (id: number) => {
            try {
                const result = await ProductService.getCategoryById(id);
                if (result.response?.data.message === 'success') {
                    setCategoryById(result.response?.data.data);
                }       
            } catch (error) {
                console.error('API call failed: ', error);
                navigate('./*');
            }
        };

        useEffect(() => {
            getAllCategories();
            getAllProduct();
            getAllManufacturer();
            getAllProductAttribute();
        }, []);

        const handleAdd = () => {
            setIsModalOpen(true);
            setEditingItem(null);
            form.resetFields();
            setInputs([""]);
            setInputsPromotion([""])
        };

        const handleEdit = async (record: any) => {
            const result = await ProductService.getProductById(record.id);
            setIsModalOpen(true);
            setEditingItem(result.response?.data.data);

            setFileName(result.response?.data.data.logo);
            // console.log("result.response?.data.data", result.response?.data.data)

            const logoLinks = result.response?.data.data.logo.split('|'); 
            const logoFileList = logoLinks.map((link : any, index : number) => ({
                uid: `-${index}`, 
                name: link.split('/').pop(), 
                status: 'done',  
                url: link
            }));


            const specificationList = result.response?.data.data.specification?.split('|') ;
            setInputs([...specificationList, '']);
            const promotionList = result.response?.data.data.promotion?.split('|') ;
            setInputsPromotion([...promotionList, '']);

            const mapAttributesToGroups = (attributeGroups: any[], productAttributes: any[]) => {

                const attributesByGroup: { [key: number]: any[] } = {};
            
                productAttributes.forEach(attr => {
                    const groupId = attr.attributeGroupId;
                    if (!attributesByGroup[groupId]) {
                        attributesByGroup[groupId] = [];
                    }
                    const cleanedAttribute = {
                        id:attr.attributeId,
                        attributeId: attr.attributeId,
                        price: attr.price,
                        isSelected: attr.isSelected
                    };
            
                    attributesByGroup[groupId].push(cleanedAttribute);
                });
            

                return attributeGroups.map(group => {
                    return {
                        attributeGroupName: group.attributeGroupName,
                        attributes: attributesByGroup[group.id] || []
                    };
                });
            };
            const selectedManufacturers = result.response?.data.data.manufacturers.map((item1: any) => {
                const manufacturer = allManufacturer.find((item: any) => item.key === item1.id);
                return manufacturer ? manufacturer.key : null; 
            }).filter(Boolean);
            const selectedCategories = result.response?.data.data.categories.map((item1: any) => {
                const category = listCate.find((item: any) => item.key === item1.id);
                return category ? category.key : null;
            }).filter(Boolean);

            const status = result.response?.data.data.status;
            const isOnSale = result.response?.data.data.isOnSale;

            const formArttributeGroupsEdit = mapAttributesToGroups(result.response?.data.data.attributeGroups,result.response?.data.data.productAttributes)

            let description = result.response?.data.data.description;

            // const document = parseDocument(description);
            // description = DomUtils.getOuterHTML(document);
            const processDescription = (description: string) => {
                // Đảm bảo các thẻ rỗng được giữ lại
                return description.replace(/<p><\/p>/g, '<p><br></p>');
            };
            
            // if (description && !description.includes('<p><br></p>')) {
            //     description = description.replace(/<\/p>/g, '</p><p><br></p>');  
            // }
            // console.log("description", description); 

            setFormArttributeGroups(formArttributeGroupsEdit)
            form.setFieldsValue({ ...result.response?.data.data, 
                logo: logoFileList,
                attributeGroups: formArttributeGroups, 
                manufacturerIds: selectedManufacturers,
                categoryIds: selectedCategories,
                isOnSale:isOnSale,
                description: processDescription(result.response?.data.data.description),
                // status: status,
                // specification: specificationList,
                // promotion:promotionList,
             });
        };

        


        const handleDelete = async (key: number) => {
            const obj = {
                id: key
            }
            const result = await ProductService.deleteProduct(obj);
            if (result.response?.data.message === "success") {
                // alert("Xóa thành công")
                api.success({
                    message: 'Xóa sản phẩm thành công',
                    showProgress: true,
                    pauseOnHover: true,
                }); 
                window.location.reload();
            }
        };

        const [status, setStatus] = useState(null); // Khởi tạo trạng thái để lưu giá trị "status"

        const handleStatusChange = (value: any) => {
            setStatus(value); // Cập nhật trạng thái khi chọn giá trị từ Select
        };

        const [addProduct, setAddProduct] = useState()

        const [fileName, setFileName] = useState("")
        const [fileNames, setFileNames] = useState<string[]>([]);
        const [lastFileNames, setLastFileNames] = useState<string[]>([]);

        const { REACT_APP_API_URL } = process.env;

        const handleUpload = async (options: any) => {
            const { file, onSuccess } = options;

            const formData = new FormData();
            formData.append('file', file);
            try {
                const result = await NewsService.uploadFileNewsCMS(formData);
    
                if (result.response) {
                    onSuccess(result.response.data);
                    // setFileName(result.response.data.data);
                    const uploadedFileName = `https://api.lehieucomputer.com${result.response.data.data}`;
                    setFileNames((prevFileNames : any) => {
                            const updatedFileNames = [...prevFileNames, uploadedFileName];
                            if (updatedFileNames.length > 1) {
                                updatedFileNames.pop()
                            }
                            return updatedFileNames;
                    });
                }         
            } catch (error) {
                console.error('API call failed: ', error);
                navigate('./*');
            }

        };
        const [selectedAttributes, setSelectedAttributes] = useState<any[]>([]);
        const [divs, setDivs] = useState<string[]>([]);
        const [formArttributeGroups, setFormArttributeGroups] = useState<any[]>([]);
        const [attributeGroups, setAttributeGroups] = useState<any[]>([]);

        const handleAddClick = () => {
            setFormArttributeGroups([...formArttributeGroups, {
                attributeGroupName: '',
                attributes: [
                ]
            }])
            setDivs([...divs, `Div ${divs.length + 1}`]);
        };

        const handleRemoveAttrClick = (groupIndex: any, attributeIndex: any) => {
            // Create a new array to avoid mutating the state directly
            const updatedFormArttributeGroups = [...formArttributeGroups];

            // Update the specific group
            updatedFormArttributeGroups[groupIndex] = {
                ...updatedFormArttributeGroups[groupIndex],
                attributes: updatedFormArttributeGroups[groupIndex].attributes.filter((_: any, index: any) => index !== attributeIndex)
            };

            // Update the state with the modified array
            setFormArttributeGroups(updatedFormArttributeGroups);
        };

        const handleRemoveClick = (groupIndex: any) => {
            const updatedFormArttributeGroups = formArttributeGroups.filter((_, index) => index !== groupIndex);
            setFormArttributeGroups(updatedFormArttributeGroups);
        };

        // const [attributeForm, setAttributeForm] = useState<any[]>([]);
        // console.log("formArttributeGroups", formArttributeGroups)


        const handleOk = async () => {
            try {
                    const values = await form.validateFields();

                    if (editingItem) {
                        const specificationKeys = Object.keys(values).filter(name => name.startsWith('specification-'));
                        const filteredSpecs = specificationKeys
                        .map(key => values[key]) 
                        .filter((spec: string | undefined) => spec && typeof spec === 'string' && spec.trim() !== '');
                        const specsString = filteredSpecs.join('|');


                        const promotionKeys = Object.keys(values).filter(name => name.startsWith('promotion-'));
                        const filteredPro = promotionKeys
                        .map(key => values[key]) 
                        .filter((pro: string | undefined) => pro && typeof pro === 'string' && pro.trim() !== '');
                        const promotionString = filteredPro.join('|');

                        const newValues = {
                            // ...values,
                            id: editingItem.id,
                            prodName: values.prodName || editingItem.prodName,
                            logo: fileName || "",
                            specification: specsString ,
                            promotion: promotionString,
                            details: values.details || editingItem.details || "",
                            video: values.video || editingItem.video || "",
                            quarantee: 0,
                            price: +values.price || editingItem.price,
                            fixedPrice: +values.fixedPrice || editingItem.fixedPrice,
                            description: values.description || editingItem.description || "",
                            quantity: +values.quantity || editingItem.quantity,
                            status: values.status ||editingItem.status,
                            isOnSale: values.isOnSale,
                            productCategoryId: +values.productCategoryId || +editingItem.productCategoryId ,
                            manufacturerIds: values.manufacturerIds  || editingItem.manufacturerIds || [],
                            attributeGroups: formArttributeGroups ||[],
                            categoryIds: values.categoryIds || editingItem.categoryIds|| [],
                            content:values.content|| "",
                        };
                        try {
                            let result;
                            if (editingItem) {
                                result = await ProductService.updateProduct({ ...newValues, id: editingItem.id });
                            }
            
                            if (result?.response?.data?.message === "success") {
                                api.success({
                                    message: 'Update thành công' ,
                                    showProgress: true,
                                    pauseOnHover: true,
                                });
                                window.location.reload();
                            }
                        } catch (error) {
                            api.error({
                                message: 'Có lỗi xảy ra!',
                                description: 'Vui lòng điền đầy đủ dữ liệu cần thiết',
                            });
                        }
                    } else {
                        const specificationKeys = Object.keys(values).filter(name => name.startsWith('specification-'));
                    const filteredSpecs = specificationKeys
                    .map(key => values[key]) 
                    .filter((spec: string | undefined) => spec && typeof spec === 'string' && spec.trim() !== '');
                    const specsString = filteredSpecs.join('|');


                    const promotionKeys = Object.keys(values).filter(name => name.startsWith('promotion-'));
                    const filteredPro = promotionKeys
                    .map(key => values[key]) 
                    .filter((pro: string | undefined) => pro && typeof pro === 'string' && pro.trim() !== '');
                    const promotionString = filteredPro.join('|');
                    

                        const requiredFields = [
                            'prodName',
                            'status',
                        ];            
                        const missingFields = requiredFields.filter(field => !values[field]);
                
                        if (missingFields.length > 0) {
                            return api.error({
                                message: 'Thiếu thông tin bắt buộc!',
                                description: `Vui lòng điền đầy đủ các dữ liệu cần thiết`,
                            });
                        }
                        const newValues = {
                            // ...values,
                            logo: fileName || "",
                            prodName: values.prodName || "",
                            specification: specsString,
                            promotion: promotionString ,
                            details: values.details || "",
                            video: values.video || "",
                            quarantee: 0,
                            price: +values.price || 0,
                            fixedPrice: +values.fixedPrice || 0,
                            description: values.description || "",
                            quantity: +values.quantity || 0,
                            status: values.status || "",
                            isOnSale: values.isOnSale ||false,
                            productCategoryId: +values.productCategoryId || 0,
                            manufacturerIds: values.manufacturerIds || [],
                            attributeGroups: formArttributeGroups || [],
                            categoryIds: values.categoryIds || [],
                        };
                        // console.log("values", newValues)
                        const result = await ProductService.createProduct(newValues);
    
                        if (result?.response?.data.message=="success") {
                            setAddProduct(result.response.data.data);
                            api.success({
                                message: 'Thêm sản phẩm thành công',
                                showProgress: true,
                                pauseOnHover: true,
                            }); 
                            window.location.reload();
                        }
                    }
                    setIsModalOpen(false);
                
            } catch (error) {
                api.error({
                    message: 'Có lỗi xảy ra!',
                    description: 'Vui lòng điền đầy đủ dữ liệu cần thiết',
                });
            }
            
        };

        const handleCancel = () => {
            setIsModalOpen(false);
        };

    

        const handleInputChange = (index: number, value: string) => {
            const newGroups = [...attributeGroups];
            if (newGroups[index]) {
                newGroups[index].attributeGroupName = value;
                setAttributeGroups(newGroups);
            }
        };

        const handleAttributeGroupNameChange = (index: any, value: string) => {
            const updatedFormArttributeGroups = [...formArttributeGroups];
            updatedFormArttributeGroups[index] = {
                ...updatedFormArttributeGroups[index],
                attributeGroupName: value
            };
            setFormArttributeGroups(updatedFormArttributeGroups);
        };

        const handleSelectChange = (groupIndex: any, attributeIndex: any, value: any) => {
            const updatedFormArttributeGroups = [...formArttributeGroups];

            updatedFormArttributeGroups[groupIndex] = {
                ...updatedFormArttributeGroups[groupIndex],
                attributes: updatedFormArttributeGroups[groupIndex].attributes.map((attr: any, index: any) =>
                    index === attributeIndex ? { ...attr, attributeId: value, id : value} : attr
                )
            };
            setFormArttributeGroups(updatedFormArttributeGroups);
        };
        const handlePriceChange = (groupIndex: any, attributeIndex: any, value: any) => {
            const updatedFormArttributeGroups = [...formArttributeGroups];
            updatedFormArttributeGroups[groupIndex] = {
                ...updatedFormArttributeGroups[groupIndex],
                attributes: updatedFormArttributeGroups[groupIndex].attributes.map((attr: any, index: any) =>
                    index === attributeIndex ? { ...attr, price: value } : attr
                )
            };
            setFormArttributeGroups(updatedFormArttributeGroups);
        };

        const [addAttributesArray, setAddAttributesArray] = useState<any[]>([]);

        const handleChange =async ({ fileList }: any) => {
                const updatedFileNames = fileList
                .filter((file: any) => file.status === 'done')
                .map((file: any) => file.response ? `https://api.lehieucomputer.com${file.response.data}` : `${file.url}`);
        
                    setFileNames(updatedFileNames);
                    const fileNamesString = updatedFileNames.join('|');
                    setFileName(fileNamesString);
        };


        useEffect(() => {
            // console.log("Updated fileNames", fileNames);
        }, [fileNames]);
        const handleAddAttribute = (index: any) => {
            const updatedFormArttributeGroups = [...formArttributeGroups];
            // console.log("updatedFormArttributeGroups", updatedFormArttributeGroups)
            updatedFormArttributeGroups[index] = {
                ...updatedFormArttributeGroups[index],
                attributes: [
                    ...updatedFormArttributeGroups[index].attributes,
                    {
                        id: null,
                        price: 0,
                        isSelected: updatedFormArttributeGroups[index].attributes.length > 0 ? false : true
                    }
                ]
            };
            setFormArttributeGroups(updatedFormArttributeGroups);
        };

        const handleRadioChange = (groupIndex: any, attributeIndex: any, value: any) => {
            // Create a copy of the current state
            const updatedFormArttributeGroups = [...formArttributeGroups];

            // Update the specific attribute in the specific group
            updatedFormArttributeGroups[groupIndex] = {
                ...updatedFormArttributeGroups[groupIndex],
                attributes: updatedFormArttributeGroups[groupIndex].attributes.map((attr: any, index: any) =>
                    index === attributeIndex ? { ...attr, isSelected: value } : { ...attr, isSelected: false }  // Unselect others
                )
            };

            // Update the state with the new array
            setFormArttributeGroups(updatedFormArttributeGroups);
        }; 
        const [inputs, setInputs] = useState(['']);
        const [inputsPromotion, setInputsPromotion] = useState(['']);

        useEffect(() => {
            // Set the values for the form
            const fieldsValue = inputs.reduce((acc : any, value, index) => {
              acc[`specification-${index}`] = value;
              return acc;
            }, {});
        
            form.setFieldsValue(fieldsValue);
          }, [form, inputs]);

          useEffect(() => {
            const fieldsValue = inputsPromotion.reduce((acc : any, value, index) => {
              acc[`promotion-${index}`] = value;
              return acc;
            }, {});
        
            form.setFieldsValue(fieldsValue);
          }, [form, inputsPromotion]);

        const handleInputSpeChange = (index : any, e: any) => {
            const newInputs = [...inputs];
            newInputs[index] = e.target.value;
    
            if (e.target.value.trim() !== '' && index === inputs.length - 1) {
                setInputs([...newInputs, '']);
            } else {
                if (index === inputs.length - 2 && e.target.value.trim() === '' && inputs.length > 1) {
                    newInputs.pop();
                    setInputs(newInputs)
                } else {
                    setInputs(newInputs);
                }
            }
        };

        const handleInputProChange = (index : any, e: any) => {
            const newInputs = [...inputsPromotion];
            newInputs[index] = e.target.value;

            if (e.target.value.trim() !== '' && index === inputsPromotion.length - 1) {
                setInputsPromotion([...newInputs, '']);
            } else {
                if (index === inputsPromotion.length - 2 && e.target.value.trim() === '' && inputsPromotion.length > 1) {
                    newInputs.pop();
                    setInputsPromotion(newInputs)
                } else {
                    setInputsPromotion(newInputs);
                }
            }
        };

        const columns = [
            {
                title: 'Tên sản phẩm',
                dataIndex: 'prodName',
                key: 'prodName',
            },
            // {
            //     title: 'Logo',
            //     dataIndex: 'logo',
            //     key: 'logo',
            // },
            {
                title: 'Giá',
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: 'Số lượng',
                dataIndex: 'quantity',
                key: 'quantity',
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                render: (status : any) => (
                    <span style={{ color: status === 'Active' ? 'green' : 'red', fontWeight: 'bold' }}>
                        {status === 'Active' ? 'Hoạt động' : 'Không hoạt động'}
                    </span>
                ),
            },
            {
                title: 'Danh mục gốc',
                dataIndex: 'productCategoryId',
                key: 'productCategoryId',
                render: (productCategoryId : any) => {
                    const category = listCate.find((cat: any) => cat.key === productCategoryId);
                    if (category) {
                        return <span>{category.categoryName}</span>;  // Hiển thị tên của danh mục
                    }
                    return <span>Không có danh mục gốc</span>;
                },
            },
            {
                title: 'Sửa/Xóa',
                key: 'actions',
                render: (_: any, record: any) => (
                    <span className='flex flex-row'>
                        <Button type="link" onClick={() => handleEdit(record)}>
                            <UserPenIconComponent style='w-5 h-5 fill-colorSecondary' />
                        </Button>
                        <Popconfirm
                            title="Bạn có muốn xóa mục này không?"
                            onConfirm={() => handleDelete(record?.id)}
                        >
                            <Button type="link" danger>
                                <DeleteIconComponent style='w-5 h-5 fill-colorThird' />
                            </Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ];

        const { Option } = Select;

        const [availableSubcategories, setAvailableSubcategories] = useState([]);

        const [searchTerm, setSearchTerm] = useState('');
        const { Search } = Input;
        // const onSearch: SearchProps['onSearch'] = (value) => {
        //     setSearchTerm(value);
        //     const filtered : any = allProduct.filter((product : any) =>
        //       product?.prodName.toLowerCase().includes(value.toLowerCase()) 
        //     );
        //     setAllProduct(filtered); 
        //   };


        const [filteredProducts, setFilteredProducts] = useState<[]>([]);
          const onSearchChange = (e : any) => {
            const value = e.target.value;
            setSearchTerm(value);
        
            if (value.trim() === '') {
                setFilteredProducts(allProduct);
            } else {
                const filtered: any = allProduct.filter((product: any) => {
                    const productNameMatch = product?.prodName?.toLowerCase().includes(value.toLowerCase());
                    const categoryMatch = listCate.some((category: any) => 
                        category?.key === product?.productCategoryId && 
                        category?.categoryName.toLowerCase().includes(value.toLowerCase())
                      );
                    return productNameMatch || categoryMatch;
                  });
              
                  setFilteredProducts(filtered);
            }
          };

        return (
            <div>
                {contextHolder}
                <div className='flex justify-between'>
                    <Button size='middle' type="primary" onClick={handleAdd} style={{ marginBottom: 16, marginRight:16 }}>
                        Thêm sản phẩm
                    </Button>
                    <div >
                        <Search size='middle' placeholder="Nhập tên tìm kiếm" 
                        onChange={onSearchChange}
                        value={searchTerm}
                        className='custom-search'
                        enterButton
                        />
                    </div>
                </div>
                <Table columns={columns} dataSource={filteredProducts} />

                <Modal destroyOnClose={false} forceRender={true} title={editingItem ? 'Chỉnh sửa' : 'Thêm mới'} width={1000}  open={isModalOpen} onOk={handleOk} onCancel={handleCancel} >
                    <Form form={form} layout="vertical">
                            <Collapse className='flex flex-col w-full'>
                                <Panel header="Thông tin sản phẩm" key="1" className='flex flex-col' forceRender={true}>
                                    <Form.Item name="prodName" label="Sản phẩm" rules={[{ required: true, message: 'Hãy nhập tên sản phẩm!' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="price" label="Giá khuyến mãi" rules={[{ required: false, message: 'Hãy nhập giá!' }]}>
                                        <InputNumber className='w-full' />
                                    </Form.Item>
                                    <Form.Item name="fixedPrice" label="Giá gốc" rules={[{ required: true, message: 'Hãy nhập giá nhập!' }]}>
                                        <InputNumber className='w-full' />
                                    </Form.Item>
                                    <Form.Item
                                        name="logo"
                                        label="Hình Ảnh"
                                        valuePropName="fileList"
                                        getValueFromEvent={(e) => {
                                            if (Array.isArray(e)) {
                                                return e;
                                            }
                                            return e && e.fileList;
                                        }}
                                        rules={[{ required: true, message: 'Hãy nhập Hình Ảnh!' }]}
                                    >
                                        <Upload
                                            name="logo"
                                            listType="picture"
                                            customRequest={handleUpload}
                                            multiple={true}
                                            onChange={handleChange}
                                        >
                                            <Button icon={<UploadIconComponent />}>Chọn tệp</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item name="manufacturerIds" label="Nhà sản xuất" rules={[{ required: false, message: 'Hãy chọn nhà sản xuất!' }]}>
                                        <Select mode="multiple" placeholder="Chọn danh mục phụ" >
                                            {allManufacturer.map((item) => (
                                                <Option key={`${item?.prdManufacturerName}-${item.id}`} value={item?.key}>
                                                    {item?.prdManufacturerName}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="productCategoryId" label="Danh mục gốc" rules={[{ required: true, message: 'Hãy chọn danh mục gốc!' }]}>
                                        <Select placeholder="Chọn danh mục gốc">
                                            {listCate.map((subcategory) => (
                                                <Option key={`${subcategory?.categoryName}-${subcategory.id}`} value={subcategory?.key}>
                                                    {subcategory?.categoryName}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="categoryIds" label="Danh mục" rules={[{ required: true, message: 'Hãy chọn danh mục!' }]}>
                                        <Select mode='multiple' placeholder="Chọn danh mục">
                                            {listCate.map((subcategory) => (
                                                <Option key={`${subcategory?.categoryName}-${subcategory.id}`} value={subcategory?.key}>
                                                    {subcategory?.categoryName}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    
                                    <Form.Item name="quantity" label="Số lượng" rules={[{ required: false, message: 'Hãy chọn số lượng!' }]}>
                                        <Input />
                                    </Form.Item>
                                </Panel>
                                <Panel header="Mô tả" key="2" forceRender={true}>

                                    <Form.Item name="description" label="Mô tả" rules={[{ required: false }]}>
                                        <QuillEditorWrapper />
                                    </Form.Item>
                                    {/* <Form.Item name="details" label="Chi tiết" rules={[{ required: false }]}>
                                        <QuillEditorWrapper />
                                    </Form.Item> */}
                                    <Form.Item name="video" label="Video" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                    </Panel>
                                    <Panel header="Đặc điểm kỹ thuật" key="3" forceRender={true}>
                                    
                                    {inputs.map((input : any, index) => (
                                        <Form.Item   
                                        name={`specification-${index}`}
                                        // name={editingItem ? undefined : `specification-${index}`}
                                        key={`specification-${index}`}
                                        label={index === 0 ? "Đặc điểm kỹ thuật" : ""} rules={[{ required: false }]}>
                                            <Input 
                                                // defaultValue={editingItem ? input : undefined}
                                                // value={input}
                                                onChange={(e) => handleInputSpeChange(index, e)} 
                                                />
                                        </Form.Item>
                                    ))}
                                    </Panel>
                                
                                    <Panel header="Nhóm sản phẩm" key="4" forceRender={true}>
                                        <Button className='mb-5' type='primary' onClick={handleAddClick}>Thêm nhóm sản phẩm</Button>
                                        {formArttributeGroups.map((item, index) => (
                                            <Form.Item name="attributeGroups" label={
                                                <div style={{ display: 'flex' }}><h1 className='mr-2'>Nhóm sản phẩm </h1><div onClick={() => handleRemoveClick(index)}>
                                                    <Button type='primary'>Xoá nhóm</Button>
                                                </div>
                                                </div>
                                            } rules={[{ required: false }]}>
                                                <div>
                                                    <label>
                                                        Tên nhóm:
                                                        <Input
                                                            type="text"
                                                            value={item.attributeGroupName}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAttributeGroupNameChange(index, e.target.value)}
                                                            required
                                                        />
                                                    </label>
                                                </div>
                                                <div className='mb-5'>
                                                    <Row gutter={[8, 8]}>
                                                        <Col span={8} >
                                                            Thuộc tính
                                                        </Col>
                                                        <Col span={8} >
                                                            Giá
                                                        </Col>
                                                        <Col span={8} >
                                                            Action
                                                        </Col>
                                                    </Row>
                                                    {item.attributes?.map((attrAdd: any, indexAttr: any) => (


                                                        <Row gutter={[8, 8]}>

                                                            <Col span={8} >
                                                                <Select
                                                                    onChange={(value) => handleSelectChange(index, indexAttr, value)}
                                                                    value={attrAdd.id}
                                                                    className='mb-3'
                                                                >
                                                                    {allProductAttribute.map((attr: any) => (
                                                                        <Option key={`${attr?.categoryName}-${attr.id}`} value={attr?.id}>
                                                                            {attr?.attributeName}
                                                                        </Option>
                                                                    ))}
                                                                </Select>
                                                            </Col>
                                                            <Col span={8} >
                                                                <Input
                                                                    type="number"
                                                                    placeholder="Giá"
                                                                    value={attrAdd.price}
                                                                    onChange={(e) => handlePriceChange(index, indexAttr, Number(e.target.value))}
                                                                />
                                                            </Col>
                                                            <Col span={8} >
                                                                <Space>
                                                                    <Radio.Group value={attrAdd.isSelected}

                                                                        onChange={(e) => handleRadioChange(index, indexAttr, e.target.value)} >

                                                                        <Radio value={true}>mặc định</Radio>

                                                                    </Radio.Group>
                                                                    <div onClick={() => handleRemoveAttrClick(index, indexAttr)}>
                                                                        <Button type='primary'>Xóa</Button>
                                                                    </div></Space>

                                                            </Col>
                                                        </Row>


                                                    ))}
                                                    <div className='mr-5'>
                                                        <Button type='primary' onClick={() => handleAddAttribute(index)}>Thêm thuộc tính</Button>
                                                    </div>
                                                </div>

                                            </Form.Item>
                                        ))}
                                    </Panel>
                                    <Panel header="Trạng thái" key="5" forceRender={true}>
                                        <Form.Item name="status" label="Trạng thái" rules={[{ required: true, message: 'Hãy nhập trạng thái!' }]}>
                                            <Select placeholder="Chọn trạng thái" onChange={handleStatusChange}>
                                                <Option value="Active">Active</Option>
                                                <Option value="Deactivate">Deactivate</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="isOnSale" label="Trạng thái khuyến mãi" rules={[{ required: false, message: 'Hãy nhập trạng thái khuyến mãi!' }]}>
                                            <Select placeholder="Chọn trạng thái">
                                                <Option value={true}>Có</Option>
                                                <Option value={false}>Không</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name="content"
                                            label="Nội dung"
                                            rules={[{ required: false, message: 'Vui lòng nhập nội dung!' }]}
                                        >
                                            <QuillEditorWrapper />
                                        </Form.Item>
                                    </Panel>
                                    <Panel header="Khuyến mãi và bảo hành" key="6" forceRender={true}>
                                    {inputsPromotion.map((inputPro:any, index) => (
                                    <Form.Item  
                                    name={`promotion-${index}`}
                                    // name={editingItem ? undefined : `promotion-${index}`}
                                        key={`promotion-${index}`}
                                    label={index === 0 ? "Thông tin khuyến mãi và bảo hành" : ""} rules={[{ required: false }]}>
                                        <Input 
                                        // placeholder={inputPro}
                                        onChange={(e:any) => handleInputProChange(index, e)}
                                        // defaultValue={editingItem ? inputPro : undefined}
                                        />  
                                    </Form.Item>
                                    ))}
                                    </Panel>
                            
                            </Collapse>
                    </Form>
                </Modal >
            </div >
        );
    };

export default ManageItemsProduct
    ;
