/* eslint-disable no-template-curly-in-string */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, notification } from 'antd';
import { useEffect, useState } from 'react';
import HomeService from '../../services/home';

function Introduction() {
    const [data, setData] = useState<Record<string, any>>({});
    const fetchIntroduction = async () => {
        try {
            const result = await HomeService.getIntroduction();
            if (result.response?.data.message === 'success') {
                const firstItem = result.response.data.data?.[0] || null;
                setData(firstItem);
            } else {
                notification.error({
                    message: 'Có lỗi xảy ra!',
                    description: result.response?.data.message || 'Chi tiết lỗi không rõ.',
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            notification.error({
                message: 'Lỗi hệ thống máy chủ!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };

    useEffect(() => {
        fetchIntroduction();
    }, []);
    return (
        <div>
            <div className='container mx-auto xxxxl:w-full xxs:w-300 '>
                <div className='py-3.5 px-4 xs:-mx-4'>
                    <Breadcrumb
                        separator=">"
                        items={[
                            {
                                title: <a href="/" className="hover:!text-colorPrimary hover:!bg-white">Trang chủ</a>,
                            },
                            {
                                title: <a href="/gioi-thieu" className="hover:!text-colorPrimary hover:!bg-white">Giới thiệu</a>,
                            }
                        ]}
                    />
                </div>
            </div>
            <div className='container mx-auto xxxxl:w-full xxs:w-300 '>
                <div className='bg-white rounded-lg shadow-lg p-4'>
                    <div>
                        {data !== null && (
                            <div
                                dangerouslySetInnerHTML={{ __html: data.description }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Introduction;
