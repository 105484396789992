/* eslint-disable no-throw-literal */
import axiosInstance from "./axios";

const getProductById = async (id: number) => {
  try {
    const response = await axiosInstance.get(
      `/api/Home/get_product_detail?id=${id}`
    );
    if (response) {
      return {
        response,
      };
    } else {
      return { message: "Product is does not exists." };
    }
  } catch (error) {
    throw {
      error,
      message: "Internal server error.",
    };
  }
};

const getProductBySlug = async (slug: string) => {
  try {
    const response = await axiosInstance.get(
      `/api/Home/get_product_detail?slug=${slug}`
    );
    if (response) {
      return {
        response,
      };
    } else {
      return { message: "Product is does not exists." };
    }
  } catch (error) {
    throw {
      error,
      message: "Internal server error.",
    };
  }
};

const order = async (object: any) => {
  try {
    const response = await axiosInstance.post(`/api/Order/order`, object);
    if (response) {
      return {
        response,
        message: "Order successfully",
      };
    } else {
      return { message: "Can not order this product." };
    }
  } catch (error) {
    throw {
      error,
      message: "Internal server error.",
    };
  }
};

const getAllCategory = async () => {
  try {
    const response = await axiosInstance.get(`/api/Home/get_categories`);
    if (response.data) {
      return {
        data: response.data,
      };
    } else {
      return { message: "Category is null." };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};

const getAllProductByHome = async () => {
  try {
    const response = await axiosInstance.get(`/api/Home/get_product_by_home`);
    if (response.data) {
      return {
        data: response.data,
      };
    } else {
      return { message: "Have no product" };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};

const getAllProductBySearch = async (params: any) => {
  try {
    const response = await axiosInstance.get(`/api/Home/get_product_search`, {
      params,
    });

    if (response.data) {
      return {
        data: response.data,
      };
    } else {
      return { message: "Have no product" };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};

const getAllProductByCategory = async (params: any) => {
  try {
    const response = await axiosInstance.get(`/api/Home/get_product_by_category`, {
      params,
    });

    if (response.data) {
      return {
        data: response.data,
      };
    } else {
      return { message: "Have no product" };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};

const getAllFaq = async () => {
  try {
    const response = await axiosInstance.get(`/api/Home/get_faq_by_home`);
    if (response.data) {
      return {
        data: response.data,
      };
    } else {
      return { message: "Have no FAQ" };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};

const getAllBannerByHome = async () => {
  try {
    const response = await axiosInstance.get(`/api/Home/get_home_item_by_home`);
    if (response.data) {
      return {
        data: response.data,
      };
    } else {
      return { message: "Have no banner" };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};

const getAllSalesByHome = async () => {
  try {
    const response = await axiosInstance.get(`/api/Home/get_product_by_sales`);
    if (response.data) {
      return {
        data: response.data,
      };
    } else {
      return { message: "Have no product" };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};
const getTextSalesByHome = async () => {
  try {
    const response = await axiosInstance.get(`/api/Home/get_bank_promotion_text_by_home`);
    if (response.data) {
      return {
        data: response.data,
      };
    } else {
      return { message: "Have no product" };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};
const getTextTopByHome = async () => {
  try {
    const response = await axiosInstance.get(`/api/Home/get_bank_top_text_by_home`);
    if (response.data) {
      return {
        data: response.data,
      };
    } else {
      return { message: "Have no product" };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};

const getLinkSocial = async (type: string) => {
  try {
    const response = await axiosInstance.get(
      `/api/Home/get_home_item_by_home_type?homeType=${type}`
    );
    if (response) {
      return {
        response,
      };
    } else {
      return { message: "Product is does not exists." };
    }
  } catch (error) {
    throw {
      error,
      message: "Internal server error.",
    };
  }
};

const getIntroduction = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/Home/get_introduction`
    );
    if (response) {
      return {
        response,
      };
    } else {
      return { message: "Introduction is does not exists." };
    }
  } catch (error) {
    throw {
      error,
      message: "Internal server error.",
    };
  }
};



const HomeService = {
  getProductById,
  getAllCategory,
  getAllProductByHome,
  getAllProductBySearch,
  order,
  getProductBySlug,
  getAllProductByCategory,
  getAllFaq,
  getAllBannerByHome,
  getAllSalesByHome,
  getTextSalesByHome,
  getTextTopByHome,
  getLinkSocial,
  getIntroduction
};

export default HomeService;
